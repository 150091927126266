import { Autocomplete, Box, Button, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";
import { data } from "../../../components/data/users";
import UserType from "../../../components/ui/UserType";
import * as yup from "yup";

import TableShowUser from "../../../components/ui/TableShowUser";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import moment from "moment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import BtaDatePicker from "../../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../../theme/ModalStyle";
import { ToastContainer, toast } from "react-toastify";
function Users() {
  const { user } = useAuthContext();
  const [typeUser, setTypeUser] = useState("client");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState({ open: false, data: null });

  const [openCreate, setOpenCreate] = useState(false);
  const access_token = user?.access_token;
  const [data, setData] = useState({});
  const [update, setUpdate] = useState(false);
  const handleNavigateUser = (id, role) => {
    navigate("/admin/users/" + id, { state: { role: role } });
  };
  const handleClickEdit = (data) => {
    console.log(data);
    setOpenEdit({ open: true, data: data });
    setInitialvaluesUpdate({
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
      gender: data?.gender?.toUpperCase(),
      email: data?.email,
      contact: data?.phone_number,
    });
    setInitialvaluesUpdateCoach({
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
      gender: data?.gender?.toUpperCase(),
      email: data?.email,
      contact: data?.phone_number,
      biography: data?.biography,
    });
  };
  //table style
  const types = ["client"];
  const genders = ["MALE", "FEMALE"];
  useEffect(() => {
    document.title = "SPORTEC - Users";
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));
  const initialValues = {
    first_name: "",
    last_name: "",
    contact: "",
    email: "",
    date_of_birth: "",
    gender: "",
    password: "",
  };
  const [initialValuesUpdate, setInitialvaluesUpdate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    gender: "",
    contact: "",
    password: "",
  });
  const [initialValuesUpdateCoach, setInitialvaluesUpdateCoach] = useState({
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    gender: "",
    contact: "",
    password: "",
    biography: "",
  });
  const validationSchema = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    contact: yup.string().required("This field is mandatory").min(8, "8 Digits minimum").max(15, "15 Digits maximum"),
    email: yup.string().email("Invalid value").required("This field is mandatory"),
    date_of_birth: yup.date().required("This field is mandatory"),
    gender: yup.string().required("This field is mandatory"),
    password: yup.string().min(8, "8 Caracters minimum").required("This field is mandatory"),
  });
  const validationSchemaUpdate = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    contact: yup.string().required("This field is mandatory").min(8, "8 Digits minimum").max(15, "15 Digits maximum"),
    email: yup.string().email("Invalid value").required("This field is mandatory"),
    date_of_birth: yup.date().required("This field is mandatory"),
    gender: yup.string().required("This field is mandatory"),
  });
  const validationSchemaUpdateCoach = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    contact: yup.string().required("This field is mandatory").min(8, "8 Digits minimum").max(15, "15 Digits maximum"),
    email: yup.string().email("Invalid value").required("This field is mandatory"),
    date_of_birth: yup.date().required("This field is mandatory"),
    gender: yup.string().required("This field is mandatory"),
    biography: yup.string().required("This field is mandatory").max(256, "256 Caracters maximum"),
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  //get users
  useEffect(() => {
    Api.get(Urls?.GET_USERS, {
      headers: { "content-type": "application/json", Authorization: `Bearer ${access_token}` },
      params: {
        perPage: 10,
        ...(typeUser && { role: typeUser }),
        ...(search && { search: search }),
      },
    })
      .then((res) => {
        setUsers(res?.data?.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [typeUser, search, update]);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setUsers(res.data?.data);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setUsers([]);
      });
  };
  const handleClickCreateUser = () => {
    setOpenCreate(true);
  };
  const handlecloseCreateUser = () => {
    setOpenCreate(false);
  };
  const handlecloseUpdateUser = () => {
    setOpenEdit({ open: false, data: null });
  };
  const handleCreateUser = (values) => {
    Api.post(
      Urls.CREATE_USERS,
      {
        first_name: values?.first_name,
        last_name: values?.last_name,
        phone_number: values?.contact,
        date_of_birth: values?.date_of_birth,
        gender: values?.gender.toLowerCase(),
        email: values?.email,
      },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((res) => {
        setOpenCreate(false);
        setUpdate(!update);
        toast?.success("Created !");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleUpdateUser = (values) => {
    Api.patch(
      Urls.UPDATE_USER + openEdit?.data?.id,
      {
        first_name: values?.first_name,
        last_name: values?.last_name,
        phone_number: values?.contact,
        date_of_birth: values?.date_of_birth,
        gender: values?.gender.toLowerCase(),
        email: values?.email,
        ...(values?.biography && { biography: values?.biography }),
      },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((res) => {
        handlecloseUpdateUser();
        setUpdate(!update);
        toast?.success("Created !");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleUpdatePass = (values) => {
    Api.patch(
      Urls.UPDATE_USER_PASSWORD + openEdit?.data?.id + "/password",
      {
        new_password: values?.password,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      <Box>
        <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
          Users
        </Typography>
      </Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
              List of users
            </Typography>
          </Box>
          <Box justifyContent="space-between" marginTop="8px" display="flex" gap="16px" flexWrap="wrap" flexDirection="row" alignItems="center">
            <Box>
              <Button variant="primary_active" onClick={handleClickCreateUser}>
                Create user
              </Button>
            </Box>
            <Box display="flex" alignItems="center" gap="16px" flexWrap="wrap">
              <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search user" />
              <Autocomplete
                sx={{ minWidth: "250px" }}
                options={types}
                value={typeUser}
                disableClearable={true}
                getOptionLabel={(e) => e}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting facture state value", value);
                    //console.log("selecting facture state value", value.id);
                    setTypeUser(value);
                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                  } else {
                    setTypeUser(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select type user" />}
              />
            </Box>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="10%">ID</StyledTableCell>
                    <StyledTableCell width="15%">USER</StyledTableCell>
                    <StyledTableCell width="10%" align="center">
                      USER TYPE
                    </StyledTableCell>
                    <StyledTableCell width="15%" align="center">
                      SEX{" "}
                    </StyledTableCell>
                    <StyledTableCell width="15%" align="left">
                      DATE OF BIRTH
                    </StyledTableCell>
                    <StyledTableCell width="10%" align="left">
                      PHONE
                    </StyledTableCell>{" "}
                    <StyledTableCell width="15%" align="center">
                      ACTION
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user) => (
                    <StyledTableRow key={user?.id}>
                      <StyledTableCell onClick={() => handleNavigateUser(user?.id, user?.role)} align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{user?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigateUser(user?.id, user?.role)} align="left">
                        <Box>
                          <TableShowUser url_photo={user?.url_avatar} email={user?.email} full_name={user?.first_name + " " + user?.last_name} />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigateUser(user?.id, user?.role)} align="center">
                        <Box width="contain">
                          <UserType variant={user?.role} />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigateUser(user?.id, user?.role)} align="center">
                        {user?.gender}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigateUser(user?.id, user?.role)} align="left">
                        {user?.date_of_birth ? moment(user?.date_of_birth).format("dddd, MMMM DD, yyyy") : "-"}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigateUser(user?.id, user?.role)} align="left">
                        {user?.phone_number ? user?.phone_number : "-"}
                      </StyledTableCell>{" "}
                      <StyledTableCell align="center">
                        <Box justifyContent="center" display="flex" gap="2px">
                          <ButtonSquare onClick={() => handleClickEdit(user)} action="edit_outlined" />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )}
          {users?.length === 0 && !loading && <Typography textAlign="center">No users</Typography>}
          {users?.length > 0 && (
            <Box justifyContent="flex-end" display="flex" gap="4px">
              {data?.links?.prev && (
                <ButtonSquare
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action="Previous"
                />
              )}
              <Box display="flex" gap="4px" justifyContent="center">
                {data?.meta?.links?.map((link) => (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                ))}
              </Box>
              {data?.links?.next && (
                <ButtonSquare
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action="Next"
                />
              )}
            </Box>
          )}
        </Box>
      </ContentBox>
      <Modal open={openCreate} onClose={handlecloseCreateUser}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handlecloseCreateUser} />
          </Box>
          <Box>
            <Formik onSubmit={handleCreateUser} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Create user
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          First name
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Last name
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>

                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Phone number{" "}
                      </Typography>
                      <Field style={{ width: "100%" }} name="contact" type="input" id="contact" as={TextField} autoComplete="off" placeholder="(123) 456-7890" />
                      <ErrorMessage component={FormikErrorText} name="contact" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Gender{" "}
                      </Typography>
                      <Autocomplete
                        sx={{ minWidth: "100%" }}
                        options={genders}
                        getOptionLabel={(e) => e}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("gender", value);
                          } else {
                            setFieldValue("gender", null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a gender" />}
                      />
                      <ErrorMessage component={FormikErrorText} name="gender" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Date of birth{" "}
                      </Typography>
                      <Field fullWidth width="100%" name="date_of_birth" id="date_of_birth" component={BtaDatePicker} disablePast={true} maxDate={new Date()} autoComplete="off" placeholder="Select a date..." />
                      <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Email{" "}
                      </Typography>
                      <Field fullWidth name="email" type="email" id="email" as={TextField} autoComplete="off" placeholder="john@doe.com" />
                      <ErrorMessage component={FormikErrorText} name="email" />
                    </Box>

                    <Box display="flex" flexDirection="row" gap="26px">
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#C7C7C7">
                          Password{" "}
                        </Typography>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => <TextField {...field} fullWidth placeholder="Password" autoComplete="Off" type={"password"} />}
                        </Field>{" "}
                        <ErrorMessage component={FormikErrorText} name="password" />
                      </Box>
                    </Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Create
                      </Button>
                      <Button onClick={handlecloseCreateUser} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openEdit?.open} onClose={handlecloseUpdateUser}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handlecloseUpdateUser} />
          </Box>
          <Box>
            <Formik onSubmit={handleUpdateUser} validationSchema={openEdit?.data?.role === "coach" ? validationSchemaUpdateCoach : validationSchemaUpdate} initialValues={initialValuesUpdate} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update user
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          First name
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Last name
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>

                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Phone number{" "}
                      </Typography>
                      <Field style={{ width: "100%" }} name="contact" type="input" id="contact" as={TextField} autoComplete="off" placeholder="(123) 456-7890" />
                      <ErrorMessage component={FormikErrorText} name="contact" />
                    </Box>

                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Gender{" "}
                      </Typography>
                      <Autocomplete
                        sx={{ minWidth: "100%" }}
                        options={genders}
                        value={values?.gender}
                        getOptionLabel={(e) => e}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("gender", value);
                          } else {
                            setFieldValue("gender", null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a gender" />}
                      />
                      <ErrorMessage component={FormikErrorText} name="gender" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Date of birth{" "}
                      </Typography>
                      <Field fullWidth width="100%" name="date_of_birth" id="date_of_birth" component={BtaDatePicker} disablePast={true} maxDate={new Date()} autoComplete="off" placeholder="Select a date..." />
                      <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                    </Box>
                    <Box display="flex" flexDirection="row" gap="26px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Email{" "}
                        </Typography>
                        <Field disabled={true} fullWidth name="email" type="email" id="email" as={TextField} autoComplete="off" placeholder="john@doe.com" />
                        <ErrorMessage component={FormikErrorText} name="email" />
                      </Box>
                    </Box>
                    {openEdit?.data?.role === "coach" && (
                      <Box width="100%" display="flex" flexDirection="row" gap="26px">
                        <Box flex={1}>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Biography{" "}
                          </Typography>
                          <TextField
                            onChange={(e) => {
                              setFieldValue("biography", e.target?.value);
                            }}
                            value={values.biography}
                            placeholder="biography..."
                            name="biography"
                            type="input"
                            id="biography"
                            multiline
                            fullWidth
                            columns={10}
                            rows={5}
                            autoComplete="off"
                          />{" "}
                          <ErrorMessage component={FormikErrorText} name="biography" />
                        </Box>
                      </Box>
                    )}
                    <Box display="flex" flexDirection="row" alignItems="end" gap="26px">
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          New password{" "}
                        </Typography>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => <TextField {...field} fullWidth placeholder="Password" autoComplete="Off" type={"password"} />}
                        </Field>{" "}
                        <ErrorMessage component={FormikErrorText} name="password" />
                      </Box>
                      <Box>
                        <Button disabled={values?.password?.length < 7 ? true : false} variant="primary_active" onClick={() => handleUpdatePass(values)}>
                          Update
                        </Button>
                      </Box>
                    </Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Update
                      </Button>
                      <Button onClick={handlecloseUpdateUser} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Users;
