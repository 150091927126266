import React, { useEffect, useState } from "react";
import { Box, Container, AppBar, Avatar, Typography, IconButton, Divider, Menu, MenuItem, List, ListItem, Toolbar, Drawer, Button, Popover } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import btalogo from "../../../imgs/bta-logo.png";
import bell from "../../../imgs/bell.svg";
import settings from "../../../imgs/settings.svg";
import adminPicture from "../../../imgs/admin-picture.png";
import { navbarContent } from "../../data/navbarContent";
import { profileMenuContent } from "../../data/profileMenuContent";
import NavbarButton from "./NavbarButton";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import ElipseIcon from "../../../imgs/Elipse.svg";
import { NavLink, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import Api from "../../../api/Api";
import Pusher from "pusher-js";

import { Urls } from "../../../api/Urls";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import "./navbar.scss";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { toast } from "react-toastify";
import { colors } from "../../../theme/Colors";

function Navbar(props) {
  const drawerWidth = 300;
  const [data, setData] = useState({});
  const [openMenu, setOpenMenu] = useState({ open: false, id: null });
  const { user } = useAuthContext();
  const [change, setChange] = useState(false);
  const [anchorElNotif, setAnchorElNotif] = useState(false);
  const access_token = user?.access_token;
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const openProfile = Boolean(anchorElProfile);
  const [newNotif, setNewNotif] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedSubMenuCaption, setSelectedSubMenuCaption] = useState(null);
  const navigate = useNavigate();

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfileMenu = (event) => {
    setAnchorElProfile(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMobile, setAnchorElMobile] = React.useState(null);

  const open = Boolean(anchorEl);
  const openNotif = Boolean(anchorElNotif);

  const openMobile = Boolean(anchorElMobile);

  const [activeSubmenu, setActiveSubmenu] = React.useState(null);
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "eu",
    });
    const channel = pusher.subscribe(`messaging.user.${user?.data?.id}`);
    channel?.bind("NewNotificationEvent", function (data) {
      console.log(data);
      let notif = { ...data.notification, new: true };
      setNotifications((notifications) => [notif, ...notifications]);
      setNewNotif(true);
    });

    return () => {
      pusher.unsubscribe(`messaging.user.${user?.data?.id}`);
    };
  }, []);

  const handleClickNotifs = (e) => {
    setAnchorElNotif(e.currentTarget);
    setNewNotif(false);
  };
  const handleCloseNotif = () => {
    setAnchorElNotif(null);
    setChange(!change);
    setNewNotif(false);
  };
  useEffect(() => {
    Api.get(Urls.GET_USER_NOTIFS + user.data?.id + "/notifications", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 5,
      },
    })
      .then((res) => {
        setNotifications(res?.data?.data);
        setData(res?.data);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, [change]);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveSubmenu(index);
  };
  const handleClickMobile = (event, index) => {
    setAnchorElMobile(event.currentTarget);
    setActiveSubmenu(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveSubmenu(null);
    setOpenMenu({ open: false, id: null });
  };
  const handleCloseMobile = () => {
    setAnchorElMobile(null);
    setActiveSubmenu(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    setAnchorElProfile(null);

    Api.post(
      Urls.LOGOUT,
      { access_token: access_token },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    ).then((response) => {
      if (response.status === 200) {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });
        navigate(0, { replace: true });
      }
    });
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="6px">
            <Avatar src={props?.userData?.url_avatar} />
            <Box>
              <Typography fontSize="22px" fontWeight="600" color="#4B465C" sx={{ pointer: "cursor", userSelect: "none" }}>
                SPORTEC ADMIN
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List>
        {navbarContent.map((l, index) => (
          <ListItem key={index} disablePadding sx={{ padding: "2px 10px" }}>
            <NavLink className={({ isActive }) => (isActive ? "active_mobile" : "navlink_mobile")} to={l.link}>
              <NavbarButton
                sx={{
                  width: "100%",
                  display: "flex", // Use flex display
                  justifyContent: "flex-start",
                }}
                icon={l.icon}
                onClick={(event) => {
                  if (l.submenu) {
                    handleClickMobile(event, index);
                    setSelectedSubMenuCaption(l.submenu[0]?.caption);
                  }
                }}
              >
                {l.caption}
              </NavbarButton>
            </NavLink>
            {l.submenu && index === activeSubmenu && (
              <Menu
                PaperProps={{
                  elevation: 0,
                  sx: {
                    backgroundColor: `white`,
                    borderRadius: "6px",
                    overflow: "auto",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.35,
                  },
                }}
                anchorEl={anchorElMobile}
                open={openMobile}
                onClose={handleCloseMobile}
              >
                {l.submenu.map((item, submenuIndex) => (
                  <MenuItem
                    sx={{ backgroundColor: `${selectedSubMenuCaption === item.caption ? "rgba(75, 70, 92, 0.08)" : "inherit"}` }}
                    key={submenuIndex}
                    onClick={(event) => {
                      navigate(item?.link);
                      setSelectedSubMenuCaption(item?.caption);
                      handleCloseMobile();
                    }}
                  >
                    {item.caption}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 5,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setNotifications(notifications.concat(res?.data?.data));
        setData(res.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };

  const navSelector = (nav) => {
    handleCloseNotif();
    switch (nav) {
      case "PLAYER_DELETED":
        return navigate("/admin/players");
      case "ORDER_CANCELED":
        return navigate("/admin/purchases");
      case "ORDER_PAID":
        return navigate("/admin/purchases");
      case "ORDER_PENDING":
        return navigate("/admin/purchases");
      case "BOOKING_CONFIRMED":
        return navigate("/admin/dashboard", { state: { booking: true } });
      case "BOOKING_CANCELLED":
        return navigate("/admin/dashboard", { state: { booking: true } });
      case "COURT_SCHEDULE_CREATED":
        return navigate("/admin/schedule");
      case "COURT_SCHEDULE_CANCELLED":
        return navigate("/admin/schedules");
    }
  };
  const handleClickNavLink = (e, content, index) => {
    e.preventDefault();
    if (content?.submenu) {
      console.log("content with submenu");
      if (openMenu?.open) {
        setOpenMenu({ open: false, id: null });
        setAnchorEl(null);
      } else {
        setOpenMenu({ open: true, id: index });
        setAnchorEl(e.currentTarget);
      }
    } else {
      navigate(content?.link);
    }
  };
  return (
    <Box>
      {/* brand + profile + notifications */}
      <Box>
        <AppBar variant="mj_navbar" sx={{ bgcolor: "white", borderBottom: "1px solid lightgray", width: "100%", padding: "5px" }} position="sticky">
          <Container maxWidth="xl" sx={{ height: "56px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              {/* Left side (brand) */}
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="6px">
                <Avatar src={btalogo} />
                <Box>
                  <Typography fontSize="22px" fontWeight="600" color="#4B465C" sx={{ pointer: "cursor", userSelect: "none" }}>
                    SPORTEC DEMO
                  </Typography>
                </Box>
              </Box>

              {/* Right side (profile, notifications, settings) */}
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap="6px">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                  <IconButton sx={{ position: "relative" }} onClick={handleClickNotifs}>
                    {newNotif && <Box zIndex="999" top="3px" left="3px" position="absolute" height="7px" width="7px" backgroundColor="red" borderRadius="50%"></Box>}

                    <img src={bell} alt="notifications" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElNotif}
                    open={openNotif}
                    onClose={handleCloseNotif}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        backgroundColor: `white`,
                        borderRadius: "10px",
                        overflow: "auto",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                        mt: 5,

                        maxHeight: "500px",
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                  >
                    {" "}
                    <Box sx={{ overflowY: "auto", width: { xs: "300px", md: "400px", sm: "400px", lg: "400px" }, padding: "18px 18px" }}>
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Typography marginLeft="6px" fontSize="14px" fontWeight="bold">
                          Notifications
                        </Typography>

                        {notifications?.map((notif) => (
                          <Box onClick={() => navSelector(notif.type)} sx={{ padding: "5px", borderRadius: "6px", cursor: "pointer", ":hover": { filter: "brightness(0.95)", background: "white" } }} display="flex" alignItems="flex-start" gap="5px">
                            {notif?.new && <img src={ElipseIcon} alt="elipse-icon" style={{ marginTop: "8px" }} />}
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                              <Typography style={{ fontWeight: "400", fontSize: "14px", lineHeight: "160%", color: "black" }}>{notif?.title}</Typography>
                              <Typography sx={{ fontSize: "12px", fontWight: "400", color: "#666D83", lineHeight: "160%" }}>{notif?.created_at}</Typography>
                            </Box>
                          </Box>
                        ))}
                        {data?.links?.next && (
                          <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
                            <ControlPointRoundedIcon fontSize="large" sx={{ color: `${colors.main}` }} onClick={handlePagination} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Menu>
                  <IconButton onClick={() => navigate("/admin/settings")}>
                    <img src={settings} alt="settings" />
                  </IconButton>
                </Box>

                <IconButton onClick={handleOpenProfileMenu}>
                  <Avatar src={props?.userData?.url_avatar} />
                </IconButton>
                <Menu
                  anchorEl={anchorElProfile}
                  open={openProfile}
                  onClose={handleCloseProfileMenu}
                  onClick={handleCloseProfileMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      backgroundColor: `white`,
                      borderRadius: "6px",
                      overflow: "auto",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 0.5,
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center" gap="6px" padding="20px">
                    <Box>
                      <Avatar src={props?.userData?.url_avatar} />
                    </Box>
                    <Box>
                      <Typography color="#5d5a68" fontWeight="600" fontSize="16px">
                        {props?.userData?.first_name + " " + props?.userData?.last_name}
                      </Typography>
                      <Typography color="#5d5a68" fontWeight="400" fontSize="12px">
                        Admin
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box mt>
                    {profileMenuContent.map((content, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                          margin: "0px 10px",
                          borderRadius: "6px",
                        }}
                      >
                        <Box display="flex" flexDirection="row" gap="10px" width="220px" alignItems="center">
                          <Link
                            className="link"
                            to={content.link}
                            onClick={() => {
                              if (content.caption === "Sign out") logout();
                            }}
                          >
                            {content.icon}
                            <Typography color="#5d5a68">{content.caption}</Typography>
                          </Link>
                        </Box>
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>
              </Box>
            </Box>
          </Container>
        </AppBar>
      </Box>

      {/* pages menu */}
      <Box>
        <AppBar sx={{ bgcolor: "white", marginBottom: "2rem", borderBottom: "1px solid lightgray", width: "100%", border: "none", boxShadow: "0px 3px 5px rgba(0,0,0,0.08);" }} position="sticky">
          <Container maxWidth="xl" sx={{ minHeight: "56px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box flexDirection="row" gap="5px" alignItems="center" flexWrap="wrap" sx={{ display: { md: "flex", xs: "none" } }}>
              {navbarContent.map((content, index) => (
                <React.Fragment key={index}>
                  <NavLink style={{ width: "fit-content" }} key={index} className={({ isActive }) => (isActive ? "active" : "navlink")} to={content?.link} onClick={(e) => handleClickNavLink(e, content, index)}>
                    <Box>
                      <Button
                        sx={{ zIndex: 9999 }}
                        variant="navbar"
                        startIcon={content.icon}
                        /*onMouseOver={(event) => {
                          console.log("MOUSE ENTERD");
                          // if (content.submenu) {
                          // handleClick(event, index);
                          //   setSelectedSubMenuCaption(content.submenu[0]?.caption);
                          // }
                          setAnchorEl(event.currentTarget);
                          setActiveSubmenu(index);
                        }}
                        onMouseOut={(event) => {
                          console.log("MOUSE OUT");
                        }}*/
                      >
                        <Box display="flex" flexDirection="row">
                          <Typography>{content?.caption}</Typography>
                          {content.submenu && <KeyboardArrowDownRoundedIcon />}
                        </Box>
                      </Button>
                    </Box>
                  </NavLink>
                  {index === openMenu?.id && (
                    <Menu
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          backgroundColor: `white`,
                          borderRadius: "6px",
                          overflow: "auto",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                          mt: 0.5,
                        },
                      }}
                      onMouseOut={() => {
                        console.log("mouse out menu");
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      display="flex"
                      flexDirection="column"
                    >
                      {content.submenu.map((item, submenuIndex) => (
                        <MenuItem
                          sx={{ backgroundColor: `${selectedSubMenuCaption === item.caption ? "rgba(75, 70, 92, 0.08)" : "inherit"}` }}
                          key={submenuIndex}
                          onClick={() => {
                            navigate(item?.link);
                            setSelectedSubMenuCaption(item?.caption);
                            handleClose();
                          }}
                        >
                          {item.caption}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </React.Fragment>
              ))}
            </Box>
            <Box sx={{ display: { md: "none" } }}>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { md: "md" } }}>
                <MenuIcon htmlColor="red"></MenuIcon>
              </IconButton>
            </Box>
          </Container>
        </AppBar>
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}

export default Navbar;
