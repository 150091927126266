import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ContentBox from "../../components/ui/ContentBox";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useState } from "react";
import shoeIcon from "../../imgs/productShoe.svg";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import cartIcon from "../../imgs/cart.svg";
import { Urls } from "../../api/Urls";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function Purchases() {
  const [selectedType, setSelectedType] = useState("pending");
  const { user } = useAuthContext();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const access_token = user?.access_token;
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    Api.get(Urls.GET_BOOKING, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        status: selectedType,
        perPage: 6,
      },
    })
      .then((res) => {
        console.log(res);
        setBookings(res?.data?.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  }, [selectedType]);
  return (
    <Box>
      <ContentBox>
        <Box minHeight="400px" display="flex" flexDirection="column" gap="26px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Booking
              </Typography>
              <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
                total of {data?.meta?.total} Bookings{" "}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box display="flex">
              <Box onClick={() => setSelectedType("pending")} display="flex" justifyContent="center" flex={1} sx={{ cursor: "pointer", borderBottom: `2px solid ${selectedType === "pending" ? "red" : "transparent"}` }}>
                <Button sx={{ color: `${selectedType !== "pending" ? "#4B465C" : "red"}` }}>Pending</Button>
              </Box>
              <Box onClick={() => setSelectedType("confirmed")} display="flex" justifyContent="center" flex={1} sx={{ cursor: "pointer", borderBottom: `2px solid ${selectedType === "confirmed" ? "green" : "transparent"}` }}>
                <Button sx={{ color: `${selectedType !== "confirmed" ? "#4B465C" : "green"}` }}>Confirmed</Button>
              </Box>
              <Box onClick={() => setSelectedType("canceled")} display="flex" justifyContent="center" flex={1} sx={{ cursor: "pointer", borderBottom: `2px solid ${selectedType === "canceled" ? "red" : "transparent"}` }}>
                <Button sx={{ color: `${selectedType !== "canceled" ? "#4B465C" : "red"}` }}>Canceled</Button>
              </Box>
            </Box>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Box display="flex" flexDirection="column" gap="16px">
              {bookings?.length === 0 && <Typography textAlign="center">No bookings</Typography>}
              {bookings?.map((book, index) => (
                <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/players/" + book?.player_id, { state: { bookId: book?.id } })} display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Box display="flex" gap="20px" alignItems="center">
                      <Box>
                        <img src={cartIcon} style={{ height: "50px", width: "50px" }} alt="product" />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="500" lineHeight="21px" color="#FAA500">
                          ID #{book?.id}
                        </Typography>

                        <Typography fontSize="13px" fontWeight="400" lineHeight="22px" color="#4B465C">
                          {moment(book?.start_date).format("dddd, MMMM DD, yyyy")} - Total payment {book?.total_inc_tax_price} USD
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {index < bookings?.length - 1 && <Box border="1px dashed #DBDADE"></Box>}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </ContentBox>
    </Box>
  );
}

export default Purchases;
