import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { colors } from "../../theme/Colors";
import tenisPlayer from "../../imgs/login-image.png";
import { useLogin } from "../../hooks/useLogin";
import * as Yup from "yup";
import BtaLogo from "../../imgs/bta-logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { toast, ToastContainer } from "react-toastify";

function Login() {
  //initialize error, isLoading, and login function
  const { login, errorLogin, isLoading } = useLogin();

  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(values.email, values.password);
    toast.error(errorLogin);
  };

  //initial values form state
  const initialValues = {
    email: "",
    password: "",
  };

  //validators form state
  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" bgcolor="white">
      <ToastContainer />
      {/* login form */}
      <Box flex={1.5} sx={{ height: "100vh", display: { lg: "flex", xs: "none" }, justifyContent: "center", alignItems: "center", padding: "2rem" }}>
        <img width="700px" style={{ objectFit: "contain" }} src={tenisPlayer} alt="" />
      </Box>
      {/* login form */}
      <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box maxWidth="400px" padding="10px">
          {/* header */}
          <Box display="flex" flexDirection="column" textAlign={"center"} gap="6px" alignItems={"center"} marginBottom="26px">
            <Box sx={{ margin: "24px" }}>
              <img src={BtaLogo} style={{ height: "200px", width: "200px" }} alt="bta-logo" />
            </Box>
            <Typography maxWidth={400} fontSize="26px" fontWeight={700} color={colors.lightgray}>
              YOUR Tennis Club Administrator
            </Typography>
            <Typography fontSize="15px" fontWeight={400} color={colors.lightgray}>
              Please sign in to your account and start the demo
            </Typography>
          </Box>
          {/* form */}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitLogin}>
            {({ values }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                      Email
                    </Typography>
                    <Field autoComplete="off" name="email" type="email" placeholder="john@doe.com" id="email" as={TextField} fullWidth />
                    <ErrorMessage name="email" component={FormikErrorText} />
                  </Box>
                  <Box>
                    <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                      Password
                    </Typography>
                    <Field autoComplete="off" name="password" type="password" placeholder="********" id="password" as={TextField} fullWidth />
                    <ErrorMessage name="password" component={FormikErrorText} />
                  </Box>
                  <Box>
                    <Button fullWidth variant="primary_active" type="submit">
                      Sign in
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
