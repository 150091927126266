import { Autocomplete, Box, Button, TextField, Typography, Modal, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../components/ui/ContentBox";
import { terms_columns } from "../../components/data/termsColumns";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import { terms_rows } from "../../components/data/termsRows";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { courts_columns } from "../../components/data/courtsColumns";
import * as Yup from "yup";

function Courts() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [courts, setCourts] = useState([]);
  const [openCreateCourt, setOpenCreateCourt] = useState(false);
  const [openDeleteCourt, setOpenDeleteCourt] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openUpdateCourt, setOpenUpdateCourt] = useState(false);
  const [didCreate, setDidCreate] = useState(false);
  const [didDelete, setDidDelete] = useState(false);
  const [didUpdate, setDidUpdate] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState();
  const [selectedRowToUpdate, setSelectedRowToUpdate] = useState();
  const [data, setData] = useState([]);
  const [court, setCourt] = useState();
  const perPage = "?perPage=10";
  useEffect(() => {
    document.title = "SPORTEC - Courts";
  }, []);
  //get list courts
  useEffect(() => {
    setLoading(true);

    Api.get(Urls.GET_COURTS_LIST + perPage, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
      },
    })
      .then((response) => {
        console.log("courts list:", response.data.data);
        setCourts(response?.data?.data);
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  }, [didCreate, didDelete, didUpdate, search]);

  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setCourts(res.data?.data);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setCourts([]);
      });
  };

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCloseCreateCourt = () => {
    setOpenCreateCourt(false);
  };

  const handleCloseDeleteCourt = () => {
    setOpenDeleteCourt(false);
  };

  const handleCloseUpdateCourt = () => {
    setOpenUpdateCourt(false);
  };

  const initialValuesCreateCourt = {
    name: "",
    address: "",
  };

  const initialValuesUpdateCourt = {
    name: court?.name || "",
    address: court?.address || "",
  };

  const validationSchemaCreateCourt = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
  });

  const onSubmitCreateCourt = (values) => {
    Api.post(
      Urls?.CREATE_COURT,
      {
        name: values.name,
        address: values.address,
      },
      { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((response) => {
        console.log(response);
        setOpenCreateCourt(false);
        toast.success(response.data.message);
        setDidCreate(!didCreate);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const onSubmitUpdateCourt = (values, id) => {
    Api.patch(
      Urls?.UPDATE_COURT + `/${id}`,
      {
        name: values.name,
        address: values.address,
      },
      { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((response) => {
        console.log(response);
        setOpenUpdateCourt(false);
        toast.success(response.data.message);
        setDidUpdate(!didUpdate);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const handleDeleteCourt = (id) => {
    Api.delete(Urls?.DELETE_COURT + `/${id}`, { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } })
      .then((response) => {
        console.log(response);
        setOpenDeleteCourt(false);
        toast.success(response.data.message);
        setDidDelete(!didDelete);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      <Box>
        <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
          Courts
        </Typography>
      </Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
              List of courts
            </Typography>
          </Box>
          <Box marginTop="8px" flexWrap="wrap" display="flex" gap="16px" flexDirection="row" justifyContent="space-between">
            <Box>
              <Button sx={{ height: "38px" }} variant="primary_active" onClick={() => setOpenCreateCourt(true)}>
                Create court
              </Button>
            </Box>
            <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search court" />
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {courts_columns.map((col, index) => (
                      <StyledTableCell key={index} width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courts?.map((court) => (
                    <StyledTableRow key={court?.id}>
                      <StyledTableCell align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{court?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">{court.name}</StyledTableCell>
                      <StyledTableCell align="left">{court.address}</StyledTableCell>
                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" gap="16px">
                          <ButtonSquare
                            action="edit_outlined"
                            onClick={() => {
                              setOpenUpdateCourt(true);
                              setSelectedRowToUpdate(court.id);
                              setCourt(court);
                            }}
                          />
                          <ButtonSquare
                            action="delete_red"
                            onClick={() => {
                              setOpenDeleteCourt(true);
                              setSelectedRowToDelete(court.id);
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )}
          {courts?.length === 0 && !loading && <Typography textAlign="center">No courts</Typography>}
          <Box display="flex" gap="10px" flexDirection="row-reverse">
            <Box>
              {data?.links?.next && (
                <>
                  <Button
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    Next
                  </Button>
                </>
              )}
            </Box>

            <Box display="flex" gap="10px">
              {data?.meta?.links?.map((link, index) => (
                <ButtonSquare
                  key={index}
                  onClick={() => {
                    handlePagination(link.url);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action={link?.active ? "number-active" : "number"}
                  number={link.label}
                />
              ))}
            </Box>

            <Box>
              {data?.links?.prev && (
                <>
                  <Button
                    onClick={() => {
                      handlePagination(data?.links?.prev);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    Previous
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </ContentBox>

      {/* modal create court */}
      <Modal open={openCreateCourt} onClose={handleCloseCreateCourt}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseCreateCourt} />
          </Box>
          <Box>
            <Formik onSubmit={onSubmitCreateCourt} validationSchema={validationSchemaCreateCourt} initialValues={initialValuesCreateCourt} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Create term
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Court name
                      </Typography>
                      <Field style={{ width: "100%" }} name="name" type="input" id="name" as={TextField} autoComplete="off" placeholder="court 1" />
                      <ErrorMessage component={FormikErrorText} name="name" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Address
                      </Typography>
                      <Field style={{ width: "100%" }} name="address" type="input" id="address" as={TextField} autoComplete="off" placeholder="Rue mellah ali isser" />
                      <ErrorMessage component={FormikErrorText} name="address" />
                    </Box>

                    <Box display="flex" flexDirection="row-reverse" gap="10px">
                      <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseCreateCourt}>
                        Cancel
                      </Button>
                      <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                        Create
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>

      {/* modal delete */}
      <Modal open={openDeleteCourt} onClose={handleCloseDeleteCourt}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseDeleteCourt} />
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Delete court
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#4B465C">
              Are you sure you want to delete court {`#${selectedRowToDelete}`} ?
            </Typography>
            <Box display="flex" flexDirection="row-reverse" gap="10px">
              <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseDeleteCourt}>
                Cancel
              </Button>
              <Button variant="primary_active" sx={{ height: "38px" }} onClick={() => handleDeleteCourt(selectedRowToDelete)}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Modal update */}
      <Modal open={openUpdateCourt} onClose={handleCloseUpdateCourt}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseUpdateCourt} />
          </Box>
          <Box>
            <Formik
              onSubmit={(values) => {
                onSubmitUpdateCourt(values, selectedRowToUpdate);
              }}
              initialValues={initialValuesUpdateCourt}
              validationSchema={validationSchemaCreateCourt}
              enableReinitialize={true}
            >
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update term
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Court name
                      </Typography>
                      <Field style={{ width: "100%" }} name="name" type="input" id="name" as={TextField} autoComplete="off" placeholder="court 1" />
                      <ErrorMessage component={FormikErrorText} name="name" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Address
                      </Typography>
                      <Field style={{ width: "100%" }} name="address" type="input" id="address" as={TextField} autoComplete="off" placeholder="Rue mellah ali isser" />
                      <ErrorMessage component={FormikErrorText} name="address" />
                    </Box>

                    <Box display="flex" flexDirection="row-reverse" gap="10px">
                      <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseUpdateCourt}>
                        Cancel
                      </Button>
                      <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                        Update
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Courts;
