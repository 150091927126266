import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CoachDetails from "../user-coach/CoachDetails";
import { Box } from "@mui/material";
import AdminDetails from "../user-admin/AdminDetails";
import UserDetails from "../user-user/UserDetails";

function UserSelection({ isAdminUpdated, setIsAdminUpdated }) {
  const location = useLocation();
  const userType = location?.state?.role;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "SPORTEC - Users";
  }, []);
  useEffect(() => {
    if (!userType) navigate("/admin/users");
    console.log(userType, "this is user type");
  }, []);
  const userSelector = (content) => {
    switch (content) {
      case "coach":
        return <CoachDetails />;
      case "admin":
        return <AdminDetails isAdminUpdated={isAdminUpdated} setIsAdminUpdated={setIsAdminUpdated} />;
      case "client":
        return <UserDetails />;
      default:
        return;
    }
  };
  return <Box>{userSelector(userType)}</Box>;
}

export default UserSelection;
