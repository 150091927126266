import { Box, Divider, Typography, TextField, Button, Avatar, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";

import ContentBox from "../../components/ui/ContentBox";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../hooks/useAuthContext";
import uploadIcon from "../../imgs/upload-icon.svg";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import * as yup from "yup";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

function Settings() {
  const { user } = useAuthContext();
  const [files, setFiles] = useState([]);

  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const maxSize = 5 * 1024 * 1024;

  const navigate = useNavigate();
  const [isFileVerified, setIsFileVerified] = useState(false);
  const [update, setUpdate] = useState(1);
  const [setting, setSetting] = useState("");
  const [drag, setDrag] = useState(false);
  const VerifyFile = (e) => {
    console.log("rana f verify file");
    const selectedFiles = e.target?.files;

    if (selectedFiles && selectedFiles.length > 0) {
      let isError = false;

      const currentFile = selectedFiles[0];

      if (!validateFileSize(currentFile) || !validateFileType(currentFile)) {
        setError("Change file size or type");
        setIsFileVerified(false);
        isError = true;
      }
      console.log(isError, "error");
      if (!isError) {
        setError("");
        setIsFileVerified(true);
        addSlide(currentFile);
      }
    } else {
      setFiles([]);
      setError("");
      setIsFileVerified(false);
    }
  };

  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };

  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    document.title = "SPORTEC - Settings";
  }, []);
  const [initialValues, setIntialValues] = useState({
    vat: "",
  });

  const validationSchema = yup.object({
    vat: yup.string().required("This field is mandatory"),
  });
  useEffect(() => {
    Api.get(Urls.GET_SETTINGS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setIntialValues({
          vat: res?.data?.data[0].value,
          id: res?.data?.data[0].id,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data.message);
      });
  }, []);
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const handleEditSettings = (values) => {
    Api.patch(
      Urls.UPDATE_SETTINGS + initialValues?.id,
      {
        value: values.vat,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  useEffect(() => {
    Api.get(Urls?.GET_SLIDE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setFiles(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, [update]);
  const [progressUpdatePic, setProgressUpdatePic] = useState(0);
  var configsAddSlide = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUpdatePic(percentCompleted);
    },
  };
  const addSlide = (file) => {
    Api.post(
      Urls?.ADD_SLIDE,
      {
        files: [file],
      },
      configsAddSlide
    )
      .then((res) => {
        setUpdate(!update);
        setProgressUpdatePic(0);
        toast.success("file added");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const DeleteFile = (id) => {
    Api.delete(Urls?.DELETE_SLIDE + id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success("file deleted");
        setUpdate(!update);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <Box>
          <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
            Settings
          </Typography>
        </Box>
        <ContentBox p={0}>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                VAT Settings
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Formik onSubmit={(values) => handleEditSettings(values)} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                {({ values }) => (
                  <Form>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        VAT (%)
                      </Typography>
                      <Box display="flex" flexDirection="row" gap="10px">
                        <Box display="flex" flexDirection="column">
                          <Field name="vat" type="input" id="vat" as={TextField} autoComplete="off" placeholder="15%" />
                          <ErrorMessage component={FormikErrorText} name="vat" />
                        </Box>
                        <Box>
                          <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </ContentBox>
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Slider Settings
              </Typography>
            </Box>
            <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
              <input
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                accept="image/jpeg , image/png , image/jpg"
                onChange={(event) => {
                  console.log(event, "event");
                  VerifyFile(event);
                }}
                style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }}
                id="avatar-input"
                type="file"
              />
              <Box justifyContent="center" display="flex">
                <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="2px dashed #DBDADE" borderRadius="6px">
                  <img src={uploadIcon} alt="upload" />
                  <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                    {/* {!files.length && "Drop files here or click to upload"}
                                {files.length > 0 && (
                                  <ul>
                                    {files.map((file, index) => (
                                      <li key={index}>{file.name}</li>
                                    ))}
                                  </ul>
                                )} */}
                    Drop files here or click to upload
                  </Typography>
                  {error && <FormikErrorText>{error}</FormikErrorText>}
                  {progressUpdatePic !== 0 && (
                    <Box display="flex" justifyContent="center" alignItems="center" gap="16px">
                      <CircularProgress />
                      <Typography fontSize="24px" fontWeight="500">
                        {progressUpdatePic}%
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px" margin="16px 0px">
              {files?.map((file, index) => (
                <Box key={index} position="relative">
                  <Avatar variant="rounded" src={file?.file?.url_file} sx={{ height: "100px", width: "100px" }} />
                  <Button
                    variant="secondary_close"
                    onClick={() => {
                      DeleteFile(file?.id);
                    }}
                    sx={{ position: "absolute", top: -10, right: -10, width: "25px", height: "25px", padding: "5px" }}
                  >
                    <CloseRoundedIcon htmlColor="gray" width="5px" />
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        </ContentBox>
      </Box>
    </React.Fragment>
  );
}

export default Settings;
