import React, { useEffect, useState } from "react";
import ContentBox from "../../components/ui/ContentBox";
import { Avatar, Box, Chip, CircularProgress, Typography } from "@mui/material";
import productIcon from "../../imgs/product.svg";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function PopularProducts() {
  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const access_token = user?.access_token;
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    Api.get(Urls.GET_ORDERS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 6,
      },
    })
      .then((res) => {
        console.log(res);
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="26px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Orders
              </Typography>
            </Box>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Box display="flex" flexDirection="column" gap="10px">
              {data?.map((prod) => (
                <Box padding="10px" sx={{ cursor: "pointer", bgcolor: "white", ":hover": { filter: "brightness(0.95)" } }} onClick={() => navigate("/admin/purchases/" + prod.id)} display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" gap="16px" alignItems="center">
                    <Avatar variant="rounded">{prod?.user_url_avatar && <img style={{ width: "80px", height: "80px" }} src={prod?.user_url_avatar ? prod?.user_url_avatar : null} alt="product" />} </Avatar>
                    <Box>
                      <Typography fontSize="15px" lineHeight="21px" fontWeight="500" color="#4B465C">
                        {prod?.user_first_name} {prod?.user_last_name}
                      </Typography>
                      <Typography fontSize="13px" fontWeight="400" lineHeight="21px" color="#4B465C">
                        ID : #{prod?.id}
                      </Typography>
                      <Typography fontSize="13px" fontWeight="400" lineHeight="21px" color="#4B465C">
                        {prod?.created_at?.split(" ")[0]}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap="10px">
                    <Typography fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                      {parseFloat(prod?.total).toLocaleString("fr-FR")} USD
                    </Typography>
                    <Box>
                      {(prod?.status === "PAID" || prod?.status === "CANCELED") && <Chip label={prod?.status === "PAID" ? "PAID" : "CANCELED"} sx={{ backgroundColor: `${prod?.status === "PAID" ? "rgba(40, 199, 111, 0.16)" : "rgba(234, 84, 85, 0.16)"}`, color: `${prod?.status === "PAID" ? "rgba(40, 199, 111, 1)" : "rgba(234, 84, 85, 1)"}`, borderRadius: "8px" }} />}
                      {prod?.status === "PENDING" && <Chip label={"PENDING"} sx={{ backgroundColor: "rgba(255, 213, 128 , 0.5)", color: "orange", borderRadius: "8px" }} />}
                    </Box>{" "}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {data?.length === 0 && !loading && <Typography textAlign="center">No orders</Typography>}
        </Box>
      </ContentBox>
    </Box>
  );
}

export default PopularProducts;
