import React, { useEffect, useRef, useState } from "react";
import ContentBox from "../ui/ContentBox";
import { Autocomplete, Avatar, Box, Button, CircularProgress, IconButton, Modal, TextField, Typography, useScrollTrigger } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import positionIcon from "../../imgs/positionIcon.svg";
import calendarIcon from "../../imgs/calendarIcon.svg";
import CustomButton from "../ui/CustomButton";
import ballIcon from "../../imgs/ball-icon.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import btaPointIcon from "../../imgs/BTA_Point.png";
import uploadIcon from "../../imgs/upload-icon.svg";
import userIcon from "../../imgs/user-icon-black.svg";
import editIcon from "../../imgs/editIcon.svg";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../ui/FormikErrorText";
import AdminIcon from "../../imgs/admin-icon-black.svg";
import ButtonSquare from "../ui/ButtonSquare";
import * as yup from "yup";
import Cropper from "react-easy-crop";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { colors } from "../../theme/Colors";
import BtaDatePicker from "../ui/BtaDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import getCroppedImg from "../../utils/cropImage";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
function UserCard({ player_id, player_deleted_at, gender, bta_points, isAdminUpdated, setIsAdminUpdated, birthday, url_avatar, updateInfos, setUpdateInfos, edit, type, id, fullName, joined, contact, email, biography }) {
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [errorResponses, setErrorResponses] = useState(false);
  const genders = ["MALE", "FEMALE"];
  const [drag, setDrag] = useState(false);
  const [openEditPoints, setOpenEditPoints] = useState({ open: false, data: null });
  const [file, setFile] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const user_id = user?.data?.id;
  const [maxDate, setMaxDate] = useState();
  const [error, setError] = useState("");
  const [isFileVerified, setIsFileVerified] = useState(false);
  const [openChangePic, setOpenChangePic] = useState(false);
  const [openAddPlayer, setOpenAddPlayer] = useState(false);
  const [initialValuesAddPlayer, setInitialValuesAddPlayer] = useState({
    first_name: fullName.split(" ")[0],
    last_name: fullName.split(" ")[1],
    date_of_birth: birthday,
    gender: gender,
  });
  const [questions, setQuestions] = useState([]);
  const [objectResponses, setObjectResponses] = useState({});
  const formikRef = useRef();
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [image, setImage] = useState();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File([croppedImage], `${Math.floor(Math.random() * 10000000000000000)}.${format.split("/")[1]}`, { lastModified: 1534584790000, type: [`${format}`] });
    if (file && croppedImage) {
      setOnImage(false);
      setFile(file);
      file = null;
    }
  };
  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "20px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };
  useEffect(() => {
    if (openAddPlayer) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: initialValuesAddPlayer.date_of_birth,
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [openAddPlayer]);
  const maxSize = 5 * 1024 * 1024;

  const handleCloseEdit = () => {
    setOpenEditProfile(false);
  };

  const handleOpenEdit = () => {
    setOpenEditProfile(true);
  };
  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };
  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };
  const initialValues = {
    first_name: fullName?.split(" ")[0],
    last_name: fullName?.split(" ")[1],
    contact: contact,
    email: email,
    password: "",
  };
  const initialValuesCoach = {
    first_name: fullName?.split(" ")[0],
    last_name: fullName?.split(" ")[1],
    contact: contact,
    email: email,
    password: "",
    biography: biography,
  };
  const [initialValuesPoints, setInitialValuesPoints] = useState({
    points: 0,
  });
  const validationSchema = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    contact: yup.string().required("This field is mandatory").min(8, "8 Digits minimum").max(15, "15 Digits maximum"),
    email: yup.string().email("Invalid value").required("This field is mandatory"),
  });
  const validationSchemaCoach = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    contact: yup.string().required("This field is mandatory").min(8, "8 Digits minimum").max(15, "15 Digits maximum"),
    email: yup.string().email("Invalid value").required("This field is mandatory"),
    biography: yup.string().required("This field is mandatory").max(256, "256 Caracters maximum"),
  });
  const validationSchemaPlayer = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    date_of_birth: yup.date().required("This field is mandatory"),
    gender: yup.string().required("This field is mandatory"),
  });
  const validationSchemaPoints = yup.object({
    points: yup.number("Enter a valid value").min(0, "Enter a valid value").required("This field is mandatory"),
  });
  const handleCloseUpdate = () => {
    setOpenEditPoints({ open: false, data: null });
  };
  const handleClickAvatar = () => {
    setOpenChangePic(true);
  };
  const handleCloseEditPic = () => {
    setOpenChangePic(false);
    setError("");
    setFile(null);
    setImage(null);
    setFormat(null);
    setError("");
  };
  useEffect(() => {
    // Obtenir la date d'aujourd'hui
    var aujourd_hui = new Date();

    // Soustraire deux ans
    aujourd_hui.setFullYear(aujourd_hui.getFullYear() - 2);

    // Obtenir les composants de la date
    var annee = aujourd_hui.getFullYear();
    var mois = aujourd_hui.getMonth() + 1; // Mois commence à 0, donc ajoutez 1
    var jour = aujourd_hui.getDate();

    // Formater la date au format "YYYY-MM-DD"
    var date_il_y_a_deux_ans = annee + "-" + (mois < 10 ? "0" : "") + mois + "-" + (jour < 10 ? "0" : "") + jour;
    console.log(date_il_y_a_deux_ans, "date il ya deux ans");
    setMaxDate(date_il_y_a_deux_ans);
  }, []);
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const [progressUpdatePic, setProgressUpdatePic] = useState(0);
  var configsPic = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUpdatePic(percentCompleted);
    },
  };
  const SubmitUpdatePic = () => {
    Api.post(
      Urls?.UPDATE_USER_AVATAR + id + "/avatar",
      {
        file: file,
      },
      configsPic
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        setProgressUpdatePic(0);
        setOpenChangePic(false);
        setFile(null);
        toast.success(res?.data?.message);
        console.log(id, user_id, "ids");
        if (id == user_id) {
          setIsAdminUpdated(!isAdminUpdated);
          console.log("update admin");
        }
      })
      .catch((err) => {
        console.log(err);
        setProgressUpdatePic(0);
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleUpdateInfos = (values) => {
    Api.patch(
      Urls.UPDATE_USER + id,
      {
        ...(values?.first_name && { first_name: values?.first_name }),
        ...(values?.last_name && { last_name: values?.last_name }),
        ...(values?.contact && { phone_number: values?.contact }),
        ...(values?.password && { password: values?.password }),
        ...(values?.biography && { biography: values?.biography }),
      },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        toast.success(res?.data?.message);

        setOpenEditProfile(false);
        if (id == user_id) {
          setIsAdminUpdated(!isAdminUpdated);
        }
      })
      .catch((err) => {
        console.log(err);
        toast?.error(err?.response?.data?.message);
      });
  };
  const submitUpdatePoints = (values) => {
    Api.patch(
      Urls.UPDATE_USER + id,
      {
        bta_points: values?.points,
      },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        setOpenEditPoints({ open: false, data: null });
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleUpdatePass = (values) => {
    Api.patch(
      Urls.UPDATE_USER_PASSWORD + id + "/password",
      {
        new_password: values?.password,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSelectResponse = (index, id) => {
    setQuestions((prevQuestions) => {
      let array = [...prevQuestions];
      let object = array[index];
      array.splice(index, 1);
      object = { ...object, response: id };
      if (index === questions?.length - 1) array.push(object);
      else array.splice(index, 0, object);
      return array;
    });
  };
  const handleAddPlayer = (values) => {
    let error = false;
    let responseObject = {};
    for (let i = 0; i < questions.length; i++) {
      responseObject[`answer_question_${i + 1 === 1 ? "one_id" : i + 1 === 2 ? "two_id" : "three_id"}`] = questions[i]?.response;
      if (questions[i]?.response === undefined) {
        setErrorResponses(true);
        console.log(questions[i]?.response);
        error = true;
      }
    }

    const champs = {
      client_id: parseInt(id),
      is_client: true,
    };

    let new_values = { ...values, gender: values?.gender.toLowerCase() };
    console.log(new_values, error);

    let data = { ...new_values, ...responseObject, ...champs };
    if (!error) {
      Api.post(Urls?.UPDATE_PLAYER, data, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          toast?.success("Added !");
          setOpenAddPlayer(false);
          setUpdateInfos(!updateInfos);
          setErrorResponses(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  };
  const handleAddPlayerClick = () => {
    setQuestions([]);
    setOpenAddPlayer(true);
  };
  useEffect(() => {
    let object = {};
    if (dateOfBirth !== null) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: moment(dateOfBirth).format("yyyy-MM-DD"),
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [dateOfBirth]);
  const handleAddPlayerClickRestore = () => {
    Api.post(
      Urls?.RESTORE_PLAYER + player_id + "/restore",
      {},
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data.message);
      });
  };
  return (
    <Box>
      <ToastContainer />
      <ContentBox>
        <Box display="flex" sx={{ flexDirection: { xs: "column", lg: "row", sm: "row", md: "row" } }} gap="26px">
          <Box>
            <Avatar onClick={isHover && type !== "super_admin" && edit ? handleClickAvatar : null} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} variant="square" sx={{ borderRadius: "6px", height: "100px", width: "100px", filter: `${isHover && type !== "super_admin" && edit ? "brightness(0.5)" : "none"}`, transition: "0.5s" }}>
              {!url_avatar && <PersonIcon sx={{ height: "100px", width: "100px" }} />}
              {url_avatar && <img src={url_avatar} alt="profile-pic" height="100px" width="100px" />}
              {isHover && type !== "super_admin" && edit ? <CameraAltIcon fontSize="large" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", transition: "0.5s" }} /> : null}
            </Avatar>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="end">
            <Box display="flex" gap="6px" sx={{ flexDirection: { xs: "column", lg: "row", md: "row", sm: "row" }, alignItems: { xs: "start", sm: "center", md: "center", lg: "center" } }} justifyContent="space-between">
              <Box>
                <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                  {fullName ? fullName : "-"}
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="16px">
                  {type === "coach" && (
                    <Box display="flex" gap="6px" alignItems="start">
                      <img src={ballIcon} alt="position" />
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                        Coach
                      </Typography>
                    </Box>
                  )}
                  {type === "admin" && (
                    <Box display="flex" gap="6px" alignItems="start">
                      <img src={AdminIcon} alt="position" />
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                        Admin
                      </Typography>
                    </Box>
                  )}
                  {type === "user" && (
                    <Box display="flex" gap="6px" alignItems="start">
                      <img src={userIcon} alt="position" />
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                        App user
                      </Typography>
                    </Box>
                  )}

                  <Box display="flex" gap="6px" alignItems="start">
                    <img src={calendarIcon} alt="position" />
                    <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                      {joined ? "Joined " + moment(joined).format("MMMM YYYY") : "-"}
                    </Typography>
                  </Box>
                </Box>
                {type === "user" && !player_id && !player_deleted_at && (
                  <Box marginTop="5px">
                    <Button onClick={handleAddPlayerClick} variant="primary_active">
                      Add my self as a player
                    </Button>
                  </Box>
                )}
                {type === "user" && player_id && player_deleted_at && (
                  <Box marginTop="5px">
                    <Button onClick={handleAddPlayerClickRestore} variant="primary_active">
                      restore player
                    </Button>
                  </Box>
                )}
              </Box>

              <Box display="flex" flexDirection="column" gap="10px" alignItems="center">
                {bta_points !== null && type === "user" && (
                  <Box
                    onClick={() => {
                      setOpenEditPoints({ open: true, data: bta_points });
                      setInitialValuesPoints({ points: bta_points });
                    }}
                    alignItems="center"
                    display="flex"
                    gap="10px"
                  >
                    {parseFloat(bta_points) >= 0 && (
                      <Typography fontSize="18px" lineHeight="22px" fontWeight="600" color="#4B465C">
                        {bta_points} balance points
                      </Typography>
                    )}
                    {parseFloat(bta_points) < 0 && (
                      <Typography fontSize="18px" lineHeight="22px" fontWeight="600" color={colors?.main}>
                        {bta_points} balance points
                      </Typography>
                    )}
                    <IconButton>
                      <img style={{ height: "30px", width: "auto" }} src={btaPointIcon} alt="balance points" />
                    </IconButton>
                  </Box>
                )}
                {edit && (
                  <Box>
                    <CustomButton onClick={handleOpenEdit} startIcon={editIcon} variant="primary_active" text="Edit profile" active={true} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentBox>
      <Modal open={openEditProfile} onClose={handleCloseEdit}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseEdit} />
          </Box>
          <Box>
            <Formik onSubmit={handleUpdateInfos} validationSchema={type !== "coach" ? validationSchema : validationSchemaCoach} initialValues={type !== "coach" ? initialValues : initialValuesCoach} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update profile
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          First name
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Last name
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>

                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Phone number{" "}
                      </Typography>
                      <Field style={{ width: "100%" }} name="contact" type="input" id="contact" as={TextField} autoComplete="off" placeholder="(123) 456-7890" />
                      <ErrorMessage component={FormikErrorText} name="contact" />
                    </Box>
                    <Box display="flex" flexDirection="row" gap="26px">
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#C7C7C7">
                          Email{" "}
                        </Typography>
                        <Field disabled={true} style={{ width: "100%" }} name="email" type="email" id="email" as={TextField} autoComplete="off" placeholder="john@doe.com" />
                        <ErrorMessage component={FormikErrorText} name="email" />
                      </Box>
                    </Box>
                    {type === "coach" && (
                      <Box width="100%" display="flex" flexDirection="row" gap="26px">
                        <Box flex={1}>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Biography{" "}
                          </Typography>
                          <TextField
                            onChange={(e) => {
                              setFieldValue("biography", e.target?.value);
                            }}
                            value={values.biography}
                            placeholder="biography..."
                            name="biography"
                            type="input"
                            id="biography"
                            multiline
                            fullWidth
                            columns={10}
                            rows={5}
                            autoComplete="off"
                          />{" "}
                          <ErrorMessage component={FormikErrorText} name="biography" />
                        </Box>
                      </Box>
                    )}
                    <Box display="flex" flexDirection="row" alignItems="end" gap="26px">
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          New password{" "}
                        </Typography>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => <TextField {...field} fullWidth placeholder="Password" autoComplete="Off" type={"password"} />}
                        </Field>{" "}
                        <ErrorMessage component={FormikErrorText} name="password" />
                      </Box>
                      <Box>
                        <Button disabled={values?.password?.length < 7 ? true : false} variant="primary_active" onClick={() => handleUpdatePass(values)}>
                          Update
                        </Button>
                      </Box>
                    </Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Update
                      </Button>
                      <Button onClick={handleCloseEdit} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openEditPoints?.open} onClose={handleCloseUpdate}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseUpdate} />
          </Box>
          <Box>
            <Formik onSubmit={(values) => submitUpdatePoints(values)} validationSchema={validationSchemaPoints} initialValues={initialValuesPoints} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update balance
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          balance points
                        </Typography>
                        <Field style={{ width: "100%" }} name="points" type="number" id="level_name" as={TextField} autoComplete="off" placeholder="balance points..." />
                        <ErrorMessage component={FormikErrorText} name="points" />
                      </Box>
                    </Box>
                    <Box marginTop="10px" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        update
                      </Button>
                      <Button onClick={handleCloseUpdate} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openChangePic} onClose={handleCloseEditPic}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseEditPic} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Update profile picture
            </Typography>
          </Box>
          <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
            <input
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              accept="image/jpeg , image/png , image/jpg"
              onChange={(event) => {
                if (event.target.files[0]) {
                  if (validateFileSize(event.target.files[0]) && validateFileType(event.target.files[0])) {
                    setFile(event.target.files[0]);
                    setError("");
                    setOnImage(true);
                    setImage(URL.createObjectURL(event.target.files[0]));
                    setFormat(event.target.files[0].type);
                  } else {
                    setError("invalid file size or type");
                  }
                }
              }}
              style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }}
              id="avatar-input"
              type="file"
            />
            <Box justifyContent="center" display="flex">
              <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                <img src={uploadIcon} alt="upload" />
                <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                  {!file && "Drop files here or click to upload"}
                  {file && file.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" marginTop="16px" justifyContent="center">
            {error && <FormikErrorText>{error}</FormikErrorText>}
          </Box>
          {progressUpdatePic !== 0 && (
            <Box marginTop="16px" display="flex" gap="16px" alignItems="center" justifyContent="center">
              <CircularProgress />
              <Typography fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                {progressUpdatePic}%
              </Typography>
            </Box>
          )}
          <Box marginTop="26px" display="flex" justifyContent="center" gap="16px">
            <Button onClick={SubmitUpdatePic} disabled={!file ? true : false} variant="primary_active">
              Update picture
            </Button>
            <Button variant="primary_inactive" onClick={handleCloseEditPic}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openAddPlayer} onClose={() => setOpenAddPlayer(false)}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenAddPlayer(false)} />
          </Box>
          <Box>
            <Formik onSubmit={(values) => handleAddPlayer(values)} validationSchema={validationSchemaPlayer} initialValues={initialValuesAddPlayer} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update player
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          First name
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Last name
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Gender{" "}
                      </Typography>
                      <Autocomplete
                        sx={{ minWidth: "100%" }}
                        options={genders}
                        getOptionLabel={(e) => e}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("gender", value);
                          } else {
                            setFieldValue("gender", null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a gender" />}
                      />
                      <ErrorMessage component={FormikErrorText} name="gender" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Date of birth{" "}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          maxDate={maxDate}
                          value={values?.date_of_birth}
                          onChange={(e) => {
                            setFieldValue("date_of_birth", moment(e).format("yyyy-MM-DD"));
                            setDateOfBirth(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>{" "}
                      <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Questions :
                      </Typography>
                      <Box display="flex" flexDirection="column" gap="10px">
                        {questions?.map((question, index) => (
                          <Box key={question?.id} display="flex" flexDirection="column" gap="5px">
                            <Typography marginLeft="20px" fontSize="15px" fontWeight="500" color="#4B465C">
                              - {question?.value}
                            </Typography>
                            {question?.answers?.map((ans) => (
                              <Box onClick={() => handleSelectResponse(index, ans?.id)} sx={question?.response !== ans?.id ? { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px" } : { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px", backgroundColor: `${colors.main}` }} key={ans?.id}>
                                {ans.value}
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box textAlign="center">{errorResponses && <FormikErrorText>Please answer all the questions</FormikErrorText>}</Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Add
                      </Button>
                      <Button onClick={() => setOpenAddPlayer(false)} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{ backgroundColor: "#3f3f3f" }}
        open={onImage}
        onClose={() => {
          setImage(null);
          setOnImage(false);
          setFile(null);
          setOpenChangePic(false);
          setError("");
        }}
      >
        <Box height="100%" width="100%" margin="0 auto">
          <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Box height="100%" width="100%" display="flex" flexDirection="column">
              <Cropper style={styleCropper} image={image} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
            </Box>
          </Box>
          <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <FormikErrorText>{error ? error : null}</FormikErrorText>
            <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
              <Button disabled={error ? true : false} onClick={onCrop} variant="primary_active">
                Confirm
              </Button>
              <Button
                variant="primary_inactive"
                onClick={() => {
                  setImage(null);
                  setOnImage(false);
                  setFile(null);
                  setOpenChangePic(false);
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
              <Typography color="white">Zoom level : </Typography>
              <input type="range" min={1} max={3} step={0.1} value={zoom} onInput={(e) => setZoom(e.target.value)} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default UserCard;
